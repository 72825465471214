
import ApiService from "@/services/pamisDashboardApi.service";


export default {
  namespaced: true,
  state: {
    countries: [],
    sites: [],
    sitesByCountryId: [],
    workcentersBySiteId: [],
    navOrgTree: [],
    dateFilter: null,
    openNavigationGroups: [],
  },
  getters: {
    GetOpenNavigationGroups(state) {
      return state.openNavigationGroups;
    },
    GetCountries(state) {
      return state.countries;
    },
    GetSites(state) {
      return state.sites;
    },
    GetSitesByCountryId(state) {
      return state.sitesByCountryId;
    },
    GetWorkcentersBySiteId(state) {
      return state.workcentersBySiteId;
    },
    GetNavOrgTree(state) {
      return state.navOrgTree;
    },
    GetDateFilter(state) {
      return state.dateFilter;
    },
  },
  actions: {
    AddOpenNavigationGroup(ctx, item) {
      ctx.commit("AddOpenNavigationGroup", item);
    },
    RemoveOpenNavigationGroup(ctx, item) {
      ctx.commit("RemoveOpenNavigationGroup", item);
    },
    GetDateFilter(ctx, data) {
      ctx.commit("SetDateFilter", data);
    },
    GetNavOrgTree(context) {
      return new Promise((resolve, reject) => {
        ApiService.get("Organization", "GetCountriesWithWorkCenters")
          .then((response) => {
            const data = response.data
            response.data.forEach(e => {
              if (e.name.toLowerCase().includes("turkey")) {
                e.sites = e.sites.filter(s => s.name.toLowerCase().includes("plastic"))
              }
            });
            context.commit("SetNavOrgTree", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetCountries(context) {
      return new Promise((resolve, reject) => {
        ApiService.get("Organization", "GetCountries")
          .then((response) => {
            context.commit("SetCountries", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetSites(context) {
      return new Promise((resolve, reject) => {
        ApiService.get("Organization", "GetSites")
          .then((response) => {
            context.commit("SetSites", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetSitesByCountryId(context, countryId) {

      return new Promise((resolve, reject) => {
        ApiService.query("Organization/GetSitesByCountryId", {
          countryId: countryId,
        })
          .then((response) => {
            const data = response.data.datasource.filter(e => e.name.toLowerCase().includes("plastic"))
            context.commit("SetSitesByCountryId", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetWorkcentersBySiteId(context, siteId) {
      return new Promise((resolve, reject) => {
        ApiService.query("Organization/GetWorkcentersBySiteId", {
          siteId: siteId,
        })
          .then((response) => {
            context.commit("SetWorkcentersBySiteId", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    AddOpenNavigationGroup(state, item) {
      let foundSameLevelItem = state.openNavigationGroups.findIndex(
        (x) => x.level == item.level
      );
      if (foundSameLevelItem != -1)
        state.openNavigationGroups[foundSameLevelItem] = item;
      else state.openNavigationGroups.push(item);
    },
    SetNavOrgTree(state, orgTreeList) {
      window.localStorage.setItem(
        "NavbarOrganization",
        JSON.stringify(orgTreeList)
      );
      state.navOrgTree = orgTreeList;
    },
    SetCountries(state, countries) {
      state.countries = countries;
    },
    SetSites(state, sites) {
      state.sites = sites;
    },
    SetSitesByCountryId(state, sites) {
      state.sitesByCountryId = sites;
    },
    SetWorkcentersBySiteId(state, workcenters) {

      state.workcentersBySiteId = workcenters;
    },
    SetDateFilter(state, data) {
      state.dateFilter = data;
    },
  },
};
